import React from "react";
import { Row, Divider } from "antd";
import PerfectScrollbar from "react-perfect-scrollbar";
import { backgroundsList } from "./backgroundsList";
import "./SettingsBackground.scss";
import { LOCALSTORAGE_BG_KEY } from "../../../constants";

function SettingsBackground({ setBackgroundImage }) {
  const selectedBackground = localStorage.getItem(LOCALSTORAGE_BG_KEY);

  return (
    <React.Fragment>
      <Divider orientation="left">Фон</Divider>
      <Row align="middle" className="backgrounds-tab">
        <PerfectScrollbar>
          {backgroundsList.map((url, index) => (
            <div key={index} className="mb-20">
              <img
                className={selectedBackground === url ? "selected" : ""}
                width="100%"
                src={url}
                alt=""
                onClick={() => setBackgroundImage(url)}
              />
            </div>
          ))}
        </PerfectScrollbar>
      </Row>
    </React.Fragment>
  );
}

export default SettingsBackground;
