import React from "react";
import "./Jumbotron.scss";
import Gruzovik from "./gruzovik.png";
import Button from "@material-ui/core/Button";
import RequestDialog from "../RequestDialog";

function Jumbotron({ data }) {
  const [requestDialogOpened, setRequestDialogOpened] = React.useState(false);

  function openRequestDialog() {
    setRequestDialogOpened(true);
  }

  function closeRequestDialog() {
    setRequestDialogOpened(false);
  }

  return (
    <div className={"jumbotron"}>
      {data.general && (
        <h1
          dangerouslySetInnerHTML={{
            __html: data.general.siteTitle.replace("\\n", "<br>"),
          }}
        ></h1>
      )}

      <Button
        className={"request-button"}
        variant={"contained"}
        color="primary"
        disableElevation
        onClick={openRequestDialog}
      >
        заказать звонок
      </Button>

      <div className={"orange-circle"}>
        <img src={Gruzovik} alt={""} />
      </div>

      <RequestDialog {...{ requestDialogOpened, closeRequestDialog, data }} />
    </div>
  );
}

export default Jumbotron;
