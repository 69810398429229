import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import "./Header.scss";
import Navigation from "../Navigation";
import { PhoneFilled } from "@ant-design/icons";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import LogoVertical from "./logo-vertical.jpg";

export default function Header({ data }) {
  const [isDrawerVisible, setIsDrawerVisible] = React.useState(false);
  const window = useWindowSize();

  function scrollToTop() {
    global.window.scroll({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }

  return (
    <React.Fragment>
      <HideOnScroll>
        <AppBar className={"header"} id={"header"}>
          <Toolbar className={"toolbar"}>
            {window.width < 1168 && (
              <MenuOutlined
                onClick={() => setIsDrawerVisible(!isDrawerVisible)}
              />
            )}

            {data.general && (
              <div className={"logo-block"} onClick={scrollToTop}>
                <img
                  className={"logo"}
                  src={
                    window.width >= 1168 ? data.general.siteLogo : LogoVertical
                  }
                  alt={"ВМ-Логистик"}
                />
              </div>
            )}

            {window.width >= 1168 && <Navigation />}

            {data.contacts && (
              <div className={"phone-block"}>
                <PhoneFilled />
                <a href={`tel:${data.contacts.phone}`}>{data.contacts.phone}</a>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      {window.width < 1168 && (
        <Drawer
          title={"Отдел транспортной логистики ООО «ВМ-Логистик»"}
          placement={"left"}
          closable={false}
          onClose={() => setIsDrawerVisible(false)}
          visible={isDrawerVisible}
        >
          <Navigation {...{ setIsDrawerVisible, data, drawerMode: true }} />
        </Drawer>
      )}
    </React.Fragment>
  );
}

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
