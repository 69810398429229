import React from "react";
import "./Landing.scss";
import Header from "./Header";
import useData from "../hooks/useData";
import Jumbotron from "./Jumbotron";
import Services from "./Services";
import Advantages from "./Advantages";
import Partners from "./Partners";
import AboutUs from "./AboutUs";
import Contacts from "./Contacts";
import Footer from "./Footer";
import Map from "./Map";
import { BackTop, Tooltip } from "antd";
import { CaretUpOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";
import * as R from "ramda";
import Slide from "react-reveal/Slide";

function Landing() {
  const data = useData();

  /**
   * @changes document title on scroll
   */
  React.useEffect(() => {
    function changeLandingTitle() {
      const h1 = document.querySelectorAll("h1");
      const offset = window.pageYOffset;
      const closesth1Offset = [...h1]
        .map((x) => x.offsetTop)
        .reduce((prev, curr) =>
          Math.abs(curr - offset) < Math.abs(prev - offset) ? curr : prev
        );

      document.title = `ВМ-Логистик - ${
        [...h1].find((x) => x.offsetTop === closesth1Offset).innerText
      }`;
    }

    window.addEventListener("scroll", changeLandingTitle);

    return () => {
      window.removeEventListener("scroll", changeLandingTitle);
    };
  }, []);

  return (
    <React.Fragment>
      {!R.isEmpty(data) && (
        <div className={"wrapper"}>
          <Header {...{ data }} />

          <section className={"content content-first"}>
            <Fade>
              <Jumbotron {...{ data }} />
            </Fade>
            {/* <Fade> */}
            {/* <Slide left delay={2000}> */}
            <Fade>
              <Services {...{ data }} />
            </Fade>
            {/* </Slide> */}
            {/* </Fade> */}
            {/* </section> */}

            {/* <Fade> */}
            {/* <Map />s */}
            {/* </Fade> */}

            {/* <section className={"content content-first"}> */}
            <Fade>
              <Advantages {...{ data }} />
            </Fade>
          </section>

          <div className={"content-fluid"}>
            <div className={"content"}>
              <Fade>
                <AboutUs {...{ data }} />
              </Fade>
              <Fade>
                <Contacts {...{ data }} />
              </Fade>
            </div>
          </div>

          <section className={"content"}>
            <Fade>
              <Partners {...{ data }} />
            </Fade>
          </section>

          <Tooltip title="наверх">
            <BackTop>
              <div className={"backtop"}>
                <CaretUpOutlined />
              </div>
            </BackTop>
          </Tooltip>

          <Fade delay={500}>
            <Footer />
          </Fade>
        </div>
      )}
    </React.Fragment>
  );
}

export default Landing;
