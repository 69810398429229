import React from "react";
import {
  Form,
  Input,
  Upload,
  Button,
  Col,
  Row,
  message,
  Popconfirm,
} from "antd";
import {
  UploadOutlined,
  ExpandOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./SettingsEditForm.scss";
import "firebase/storage";
import { getFirebaseImageUrl, getFirebaseFileName } from "../../../constants";
import * as uuid from "uuid";

function SettingsEditForm({
  model,
  save,
  update,
  remove,
  Firebase,
  getBack,
  showAdditionalInfo,
}) {
  const [loading, setLoading] = React.useState(false);
  const [item, setItem] = React.useState(model ? model : createModel());

  React.useEffect(() => {
    return () => {
      deleteIcon(true);
    };
  }, []);

  function createModel() {
    let model = {};
    model.description = null;
    model.id = uuid.v4();

    if (showAdditionalInfo) {
      model.additionalInfo = null;
    }

    return model;
  }

  function handleChange(value, prop) {
    const updateditem = {
      ...item,
      [prop]: value,
    };

    setItem(updateditem);
  }

  function generateFormField(label, prop) {
    return (
      <Form.Item label={label}>
        <Input
          value={item[prop]}
          onChange={({ target: { value } }) => handleChange(value, prop)}
        />
      </Form.Item>
    );
  }

  function uploadIcon({ file }) {
    setLoading(true);
    const storageRef = Firebase.storage().ref();
    const fileRef = storageRef.child(file.name);

    if (item.icon) {
      deleteIcon();
    }

    fileRef
      .put(file)
      .then((snapshot) => {
        setLoading(false);
        setItem({
          ...item,
          icon: getFirebaseImageUrl(snapshot.metadata.name),
        });
      })
      .catch(() => {
        setLoading(false);
        message.error("Не удалось загрузить иконку");
      });
  }

  function deleteIcon(unmount) {
    if (item.icon && (!unmount || (unmount && !model))) {
      const storageRef = Firebase.storage().ref();
      const fileRef = storageRef.child(getFirebaseFileName(item.icon));

      fileRef.delete();
    }
  }

  function validateForm() {
    if (!item.icon) {
      message.error({ content: "Загрузите иконку", key: "UPLOAD_ICON" });
      return;
    }

    if (model) {
      update(item);
    } else {
      save(item);
    }
  }

  return (
    <div className="settings-edit-form">
      {model ? (
        <Popconfirm
          title="Вы уверены?"
          onConfirm={() => {
            deleteIcon();
            remove(item);
          }}
          okText="Да"
          cancelText="Нет"
        >
          <Button
            loading={loading}
            icon={<DeleteOutlined />}
            type="danger"
            className="delete-button"
          >
            Удалить
          </Button>
        </Popconfirm>
      ) : null}

      <Row align="bottom" className="mb-12">
        <Col span={5}>
          {item.icon ? (
            <img className="upload-icon" src={item.icon} alt="" />
          ) : (
            <ExpandOutlined
              style={{ fontSize: 100, color: "rgba(0,0,0,0.05)" }}
            />
          )}
        </Col>
        <Col span={11}>
          <Upload
            customRequest={uploadIcon}
            accept=".jpg,.jpeg,.png,.svg"
            showUploadList={false}
          >
            <Button
              loading={loading}
              icon={<UploadOutlined />}
              className="admin-button secondary"
            >
              Загрузить иконку
            </Button>
          </Upload>
        </Col>
      </Row>

      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 13 }}
        layout="horizontal"
        size="normal"
      >
        {generateFormField("Описание", "description")}
        {showAdditionalInfo && (
          <Input.TextArea
            rows={5}
            value={item.additionalInfo}
            onChange={({ target: { value } }) =>
              handleChange(value, "additionalInfo")
            }
            placeholder="Дополнительное описание"
            style={{ resize: "none" }}
            className="mb-16"
          />
        )}
      </Form>
      <Row justify="end">
        <Button
          className="admin-button secondary my-5"
          onClick={() => getBack()}
          disabled={loading}
        >
          Назад
        </Button>
        <Button
          className="admin-button"
          type="primary"
          onClick={() => validateForm()}
          disabled={loading}
        >
          Сохранить
        </Button>
      </Row>
    </div>
  );
}

export default SettingsEditForm;
