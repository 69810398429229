import React from "react";
import { message } from "antd";
import { Spin } from "antd";
import { LoadingOutlined, LoginOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import "./LoginForm.scss";

function LoginForm({ isLoading, setLoading, Firebase }) {
  const passwordInput = React.useRef(null);
  const submitIcon = React.useRef(null);
  const submitButton = React.useRef(null);
  const loginForm = React.useRef(null);

  /**
   * Returns focus to input on pressing Enter
   */
  React.useEffect(() => {
    const rootContainer = document.getElementById("root");

    function handleKey(e) {
      if (e.key === "Enter") {
        if (e.target !== passwordInput.current) {
          setTimeout(() => {
            passwordInput.current.focus();
          });
        }
      }
    }

    window.addEventListener("keydown", handleKey);
    rootContainer.style.backgroundColor = "rgb(37,37,37)";

    return () => {
      window.removeEventListener("keydown", handleKey);
      rootContainer.style.backgroundColor = null;
    };
  }, []);

  function submitLogin(event) {
    event.preventDefault();
    setLoading(true);
    passwordInput.current.focus();
    message.loading({ content: "Попытка входа...", key: "LOG_IN" });

    Firebase.auth()
      .signInWithEmailAndPassword(
        "alekyansn@gmail.com",
        passwordInput.current.value
      )
      .then(() => {
        message.success({
          content: "Вы вошли в систему как @admin",
          key: "LOG_IN",
        });
      })
      .catch((error) => {
        loginForm.current.classList.add("animated", "shake");
        loginForm.current.addEventListener("animationend", finishAnimation);
        message.error({
          content: error.message,
          key: "LOG_IN",
        });
        setLoading(false);
      });
  }

  function handleInput({ target: { value } }) {
    if (value) {
      showButton(true);
    } else {
      showButton(false);
    }
  }

  function showButton(show) {
    if (show) {
      submitIcon.current.classList.remove("hidden");
    } else {
      submitIcon.current.classList.add("hidden");
    }
  }

  function finishAnimation() {
    passwordInput.current.focus();
    loginForm.current.classList.remove("animated", "shake");
  }

  return (
    <form
      className="login-form"
      onSubmit={submitLogin}
      onBlur={() => showButton(false)}
      ref={loginForm}
    >
      <div className="login-form-input">
        <input
          type="password"
          ref={passwordInput}
          onFocus={({ target: { value } }) => {
            if (value) showButton(true);
          }}
          onKeyDownCapture={(e) => handleInput(e)}
          disabled={isLoading}
        />
        <div
          className="submit-button"
          onMouseOver={() => submitIcon.current.classList.remove("hidden")}
        >
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  ref={submitIcon}
                  className="indicator hidden"
                  style={{ fontSize: 20 }}
                  spin
                />
              }
            />
          ) : (
            <LoginOutlined ref={submitIcon} />
          )}
          <Tooltip placement="right" title="Войти в систему">
            <input ref={submitButton} type="button" onClick={submitLogin} />
          </Tooltip>
        </div>
      </div>

      <span className="login-form-message">
        {!isLoading ? "Введите пароль" : ""}
      </span>
    </form>
  );
}

export default LoginForm;
