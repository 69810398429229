import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./Landing";
import Admin from "./Admin";
import NotFoundComponent from "./NotFoundComponent";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/admin" component={Admin} />
        <Route component={NotFoundComponent} />
      </Switch>
    </Router>
  );
}

export default App;
