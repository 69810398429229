import React from "react";
import Navigation from "../Navigation";
import "./Footer.scss";

function Footer() {
  return (
    <footer className={"footer"}>
      <div className={"toolbar"}>
        <Navigation />
      </div>

      <div className={"rights-reserved"}>{new Date().getFullYear()} Все права защищены</div>
    </footer>
  );
}
export default Footer;
