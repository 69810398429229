import default1 from "../../assets/backgrounds/10-0_10.1--thumb.png";
import default2 from "../../assets/backgrounds/10-2--thumb.png";
import default3 from "../../assets/backgrounds/10-3--thumb.png";
import default4 from "../../assets/backgrounds/10-4--thumb.png";
import mojaveMorning from "../../assets/backgrounds/mojave-morning.jpg";
import mojaveDay from "../../assets/backgrounds/mojave-day.jpeg";
import mojavePurple from "../../assets/backgrounds/mojave-purple.jpg";
import mojaveNight from "../../assets/backgrounds/mojave-night.jpg";
import abstract1 from "../../assets/backgrounds/abstract1.jpg";
import abstract2 from "../../assets/backgrounds/abstract2.jpg";
import abstract3 from "../../assets/backgrounds/abstract3.jpg";
import abstract4 from "../../assets/backgrounds/abstract4.jpg";
import noise1 from "../../assets/backgrounds/noise_1.jpg";
import noise2 from "../../assets/backgrounds/noise_2.jpg";
import noise3 from "../../assets/backgrounds/noise_3.jpg";
import noise4 from "../../assets/backgrounds/noise_4.jpg";
import noise5 from "../../assets/backgrounds/art_0061.jpg";
import noise6 from "../../assets/backgrounds/art_1040.jpg";
import noise7 from "../../assets/backgrounds/art_1049.jpg";
import noise8 from "../../assets/backgrounds/art_1055.jpg";
import katalina1 from "../../assets/backgrounds/10-15-1-Dawn.jpg";
import katalina2 from "../../assets/backgrounds/10-15-4-Day-Overcast.jpg";
import katalina3 from "../../assets/backgrounds/10-15-6-Evening.jpg";
import katalina4 from "../../assets/backgrounds/10-15-8-Night.jpg";

export const backgroundsList = [
  mojaveMorning,
  mojaveDay,
  mojavePurple,
  mojaveNight,
  abstract1,
  abstract2,
  abstract3,
  abstract4,
  katalina1,
  katalina2,
  katalina3,
  katalina4,
  noise1,
  noise2,
  noise3,
  noise4,
  noise5,
  noise6,
  noise7,
  noise8,
  default1,
  default2,
  default3,
  default4
];
