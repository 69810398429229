import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { firebaseConfig } from "../../enviroment";

export default function useFirebase() {
  const [instance, setInstance] = React.useState(null);

  function initFirebase() {
    if (firebase.apps.length === 0) {
      const app = firebase.initializeApp(firebaseConfig);
      setInstance(app);
    }

    return instance;
  }

  // function updatePassword(password) {
  //   const user = firebase.auth().currentUser;

  //   return user.updatePassword(password);
  // }

  function getInstance() {
    return firebase.apps.length === 0 ? initFirebase() : firebase;
  }

  return getInstance();
}
