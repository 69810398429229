import React from "react";
import { Row, Divider, Button, List } from "antd";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./SettingsAdvantages.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { firebaseAdvantagesUrl } from "../../../constants";
import SettingsEditForm from "../SettingsEditForm";

function SettingsAdvantages({ Firebase }) {
  const [advantages, setAdvantages] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [model, setModel] = React.useState(null);

  React.useEffect(() => {
    fetch(firebaseAdvantagesUrl)
      .then((res) => res.json())
      .then((advantages) => setAdvantages(advantages || []));
  }, []);

  function save(item) {
    const updatedAdvantages = [...advantages, item];

    setAdvantages(updatedAdvantages);
    Firebase.database().ref("advantages").set(updatedAdvantages);
    setAddMode(false);
  }

  function update(item) {
    const index = advantages.findIndex((x) => x.id === item.id);

    if (index !== -1) {
      let updatedAdvantages = advantages;
      updatedAdvantages[index] = item;

      setAdvantages(updatedAdvantages);
      Firebase.database().ref("advantages").set(updatedAdvantages);
    }
    setEditMode(false);
  }

  function remove(item) {
    const index = advantages.findIndex((x) => x.id === item.id);
    let updatedAdvantages = advantages;
    updatedAdvantages.splice(index, 1);

    setAdvantages(updatedAdvantages);
    Firebase.database().ref("advantages").set(updatedAdvantages);
    setEditMode(false);
  }

  function getBack() {
    setAddMode(false);
    setEditMode(false);
    setModel(null);
  }

  function openItem(item) {
    setModel(item);
    setEditMode(true);
  }

  return (
    <React.Fragment>
      <Divider orientation="left">Наши преимущеста</Divider>
      {advantages ? (
        editMode || addMode ? (
          <SettingsEditForm
            {...{ save, update, remove, Firebase, getBack, model }}
          />
        ) : (
          <Row
            justify="space-between"
            align="middle"
            className="advantages-tab"
          >
            <PerfectScrollbar className="full-width mb-12">
              <List
                itemLayout="horizontal"
                dataSource={advantages}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      avatar={
                        <img
                          src={item.icon}
                          className="mr-8 advantage"
                          alt=""
                          onClick={() => openItem(item)}
                        />
                      }
                      title={
                        <a href="#" onClick={() => openItem(item)}>
                          {item.description}
                        </a>
                      }
                    />
                  </List.Item>
                )}
              />
            </PerfectScrollbar>
            <Button
              type="primary"
              className="admin-button"
              onClick={() => setAddMode(true)}
            >
              Добавить
            </Button>
          </Row>
        )
      ) : (
        <Row justify="center" align="middle" className="full-height">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </Row>
      )}
    </React.Fragment>
  );
}

export default SettingsAdvantages;
