import React from "react";
import {
  firebaseGeneralUrl,
  firebaseContactsUrl,
  firebaseAboutUsUrl,
  firebaseAdvantagesUrl,
  firebasePartnersUrl,
  firebaseServicesUrl,
} from "../constants";

export default function useData() {
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    Promise.all([
      fetch(firebaseGeneralUrl).then((res) => res.json()),
      fetch(firebaseContactsUrl).then((res) => res.json()),
      fetch(firebaseAboutUsUrl).then((res) => res.json()),
      fetch(firebaseAdvantagesUrl).then((res) => res.json()),
      fetch(firebasePartnersUrl).then((res) => res.json()),
      fetch(firebaseServicesUrl).then((res) => res.json()),
    ]).then(([general, contacts, aboutUs, advantages, partners, services]) => {
      setData({
        general,
        contacts,
        aboutUs,
        advantages,
        partners,
        services,
      });
    });
  }, []);

  return data;
}
