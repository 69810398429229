import React from "react";
import "./Admin.scss";
import defaultBackground from "./assets/backgrounds/mojave-night.jpg";
import LoginForm from "./components/LoginForm";
import Settings from "./components/Settings";
import useFirebase from "./hooks/useFirebase";
import { LOCALSTORAGE_BG_KEY, LOCALSTORAGE_THEME_KEY } from "../constants";

function Admin() {
  const [userAuthStateChecked, setUserAuthStateChecked] = React.useState(false);
  const [isUserLoggedIn, setUserLoggedIn] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [background, setBackground] = React.useState(defaultBackground);
  const [nightTheme, setNightTheme] = React.useState(false);
  const Firebase = useFirebase();

  /**
   * Retrieve backgrounds from local storage
   */
  React.useEffect(() => {
    const savedBackground = localStorage.getItem(LOCALSTORAGE_BG_KEY);

    if (savedBackground) {
      setBackground(savedBackground);
    }
  }, []);

  /**
   * Retrieve theme from local storage
   */
  React.useEffect(() => {
    const theme = localStorage.getItem(LOCALSTORAGE_THEME_KEY);

    if (theme === "night") {
      setNightTheme(true);
    }
  }, []);

  /**
   * Subscribe on firebase authorization state
   */
  React.useEffect(() => {
    Firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserLoggedIn(true);
      } else {
        setUserLoggedIn(false);
      }

      setLoading(false);
      setUserAuthStateChecked(true);
    });
  }, [Firebase]);

  return (
    <React.Fragment>
      {userAuthStateChecked ? (
        <section
          className={nightTheme ? "admin night-theme" : "admin"}
          style={{
            background: `url(${background}) no-repeat center/cover`,
          }}
        >
          {isUserLoggedIn ? (
            <Settings {...{ setLoading, setBackground, Firebase }} />
          ) : (
            <LoginForm {...{ isLoading, setLoading, Firebase }} />
          )}
        </section>
      ) : null}
    </React.Fragment>
  );
}

export default Admin;
