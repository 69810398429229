import React from "react";
import { Row, Divider, Form, Input } from "antd";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./SettingsContacts.scss";
import { firebaseContactsUrl } from "../../../constants";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function SettingsContacts({ Firebase }) {
  const [contacts, setContacts] = React.useState(null);

  React.useEffect(() => {
    fetch(firebaseContactsUrl)
      .then(res => res.json())
      .then(contacts => setContacts(contacts));
  }, []);

  function handleChange(value, prop) {
    const updatedContacts = {
      ...contacts,
      [prop]: value
    };
    setContacts(updatedContacts);

    Firebase.database()
      .ref("contacts")
      .set(updatedContacts);
  }

  function generateFormField(label, prop) {
    return (
      <Form.Item label={label}>
        <Input
          value={contacts[prop]}
          onChange={({ target: { value } }) => handleChange(value, prop)}
        />
      </Form.Item>
    );
  }

  return (
    <React.Fragment>
      <Divider orientation="left">Контакты</Divider>
      {contacts ? (
        <Row justify="space-between" align="middle" className="contacts-tab">
          <PerfectScrollbar className="full-width">
            <Form
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 13 }}
              layout="horizontal"
              size="normal"
            >
              {generateFormField("Телефон", "phone")}
              {generateFormField("Титул", "title")}
              {generateFormField("Титул (eng)", "titleEng")}
              {generateFormField("Адрес", "address")}
              {generateFormField("Адрес (eng)", "addressEng")}
            </Form>
          </PerfectScrollbar>
        </Row>
      ) : (
        <Row justify="center" align="middle" className="full-height">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </Row>
      )}
    </React.Fragment>
  );
}

export default SettingsContacts;
