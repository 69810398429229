import React from "react";
import "./AboutUs.scss";

function AboutUs({ data }) {
  return (
    <div className={"about-us"} id={"about-us"}>
      <h1>О нас</h1>

      <div
        className={"about-us-text"}
        dangerouslySetInnerHTML={{
          __html: data.aboutUs ? data.aboutUs : "",
        }}
      ></div>
    </div>
  );
}

export default AboutUs;
