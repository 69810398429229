import React from "react";
import "./Navigation.scss";
import { Menu } from "antd";

function Navigation({ setIsDrawerVisible, drawerMode, data }) {
  function scrollTo(event, id) {
    const element = document.getElementById(id);

    if (element) {
      event.preventDefault();
      window.scroll({
        behavior: "smooth",
        left: 0,
        top: window.scrollY > 160 ? element.offsetTop - 160 : element.offsetTop,
      });
    }

    if (setIsDrawerVisible) {
      setIsDrawerVisible(false);
    }
  }

  function renderNavlink(title, href) {
    return (
      <a href={`#${href}`} onClick={(event) => scrollTo(event, href)}>
        {title}
      </a>
    );
  }

  function renderMenuItem(title, href) {
    return (
      <Menu.Item key={href}>
        <a href={`#${href}`} onClick={(event) => scrollTo(event, href)}>
          {title}
        </a>
      </Menu.Item>
    );
  }

  return (
    <nav className="navigation">
      {!drawerMode && (
        <React.Fragment>
          {renderNavlink("Виды перевозок", "services")}
          {renderNavlink("Преимущества", "advantages")}
          {renderNavlink("О нас", "about-us")}
          {renderNavlink("Контакты", "contacts")}
          {renderNavlink("Партнеры", "partners")}
        </React.Fragment>
      )}

      {drawerMode && (
        <Menu selectedKeys={null}>
          {renderMenuItem("Виды перевозок", "services")}
          {renderMenuItem("Преимущества", "advantages")}
          {renderMenuItem("О нас", "about-us")}
          {renderMenuItem("Контакты", "contacts")}
          {renderMenuItem("Партнеры", "partners")}
          <Menu.Divider></Menu.Divider>
          {data.contacts && (
            <Menu.Item>
              <a href={`tel:${data.contacts.phone}`}>{data.contacts.phone}</a>
            </Menu.Item>
          )}
        </Menu>
      )}
    </nav>
  );
}

export default Navigation;
