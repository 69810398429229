import React from "react";
import {
  Row,
  Divider,
  Spin,
  Button,
  message,
  Upload,
  Form,
  Input,
  Col,
} from "antd";
import "./SettingsGeneral.scss";
import {
  firebaseGeneralUrl,
  getFirebaseImageUrl,
  getFirebaseFileName,
} from "../../../constants";
import PerfectScrollbar from "react-perfect-scrollbar";
import { LoadingOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import "firebase/storage";
import { Link } from "react-router-dom";

function SettingsGeneral({ Firebase }) {
  const [generalData, setGeneralData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    fetch(firebaseGeneralUrl)
      .then((res) => res.json())
      .then((general) => setGeneralData(general));
  }, []);

  function uploadAvatar({ file }) {
    setLoading(true);
    const storageRef = Firebase.storage().ref();
    const fileRef = storageRef.child(file.name);

    fileRef
      .put(file)
      .then((snapshot) => {
        deleteLogo(generalData.siteLogo);
        setLoading(false);
        handleChange(getFirebaseImageUrl(snapshot.metadata.name), "siteLogo");
      })
      .catch(() => {
        setLoading(false);
        message.error("Не удалось загрузить иконку");
      });
  }

  function deleteLogo(logo) {
    const storageRef = Firebase.storage().ref();
    const fileRef = storageRef.child(getFirebaseFileName(logo));

    fileRef.delete();
  }

  function handleChange(value, prop) {
    const updatedData = {
      ...generalData,
      [prop]: value,
    };

    setGeneralData(updatedData);
    Firebase.database().ref("general").set(updatedData);
  }

  function generateFormField(label, prop) {
    return (
      <Form.Item label={label}>
        <Input
          value={generalData[prop]}
          onChange={({ target: { value } }) => handleChange(value, prop)}
        />
      </Form.Item>
    );
  }

  return (
    <React.Fragment>
      <Divider orientation="left">Настройки</Divider>

      {generalData ? (
        <Row justify="space-between" align="middle" className="general-tab">
          <PerfectScrollbar className="full-width">
            <Row align="bottom" className="mb-20 logo-row">
              <Col span={8}>
                <img src={generalData.siteLogo} alt="" width={170} />
              </Col>
              <Col span={12}>
                <h3>Логотип</h3>
                <Upload
                  customRequest={uploadAvatar}
                  accept=".jpg,.jpeg,.png,.svg"
                  showUploadList={false}
                >
                  <Button
                    loading={loading}
                    icon={<UploadOutlined />}
                    className="admin-button secondary"
                  >
                    Сменить логотип
                  </Button>
                </Upload>
              </Col>
            </Row>

            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              layout="horizontal"
              size="normal"
            >
              {generateFormField("Заголовок сайта", "siteTitle")}
              {generateFormField("Еmail для заявок", "emailToSendRequests")}
            </Form>

            <Row justify="end">
              <Link to="/">
                <Button className="admin-button" type="primary">
                  Перейти на сайт
                </Button>
              </Link>
            </Row>
          </PerfectScrollbar>
        </Row>
      ) : (
        <Row justify="center" align="middle" className="full-height">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </Row>
      )}
    </React.Fragment>
  );
}

export default SettingsGeneral;
