import React from "react";
import "./Advantages.scss";

function Advantages({ data }) {
  function renderAdvantage(advantage) {
    return (
      <div className={"advantage-item"} key={advantage.id}>
        <img src={advantage.icon} alt={advantage.description} />
        <p className={"advantage-description"}>{advantage.description}</p>
      </div>
    );
  }

  return (
    <div className={"advantages"} id={"advantages"}>
      <h1>Наши преимущества</h1>

      <div className={"advantages-list"}>
        {(data.advantages || []).map((advantage) => renderAdvantage(advantage))}
      </div>
    </div>
  );
}

export default Advantages;
