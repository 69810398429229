import React from "react";
import { Empty } from "antd";
import "./NotFoundComponent.scss";
import { useHistory } from "react-router-dom";

function NotFoundComponent() {
  const history = useHistory();

  React.useEffect(() => {
    setTimeout(() => history.push("/"), 3000);
  }, [history]);

  React.useEffect(() => {
    document.body.classList.add("white");
    return () => document.body.classList.remove("white");
  }, []);

  return (
    <section className="not-found">
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={`Этой страницы не существует\nВы будете перенаправлены на главную страницу`}
      />
    </section>
  );
}

export default NotFoundComponent;
