import React from "react";
import { Tabs, Card, Button, Tooltip } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import Draggable from "react-draggable";
import SettingsBackground from "../SettingsBackground/SettingsBackground";
import "./Settings.scss";
import { LOCALSTORAGE_BG_KEY } from "../../../constants";
import SettingsContacts from "../SettingsContacts";
import SettingsAboutUs from "../SettingsAboutUs";
import SettingsAdvantages from "../SettingsAdvantages";
import SettingsPartners from "../SettingsPartners";
import SettingsGeneral from "../SettingsGeneral";
import SettingsServices from "../SettingsServices";

function Settings({ setLoading, setBackground, Firebase }) {
  const adminPanelHeaderTitle = "vm-logistik@admin";

  /**
   * Return draggable card into initial position on resize
   */
  React.useEffect(() => {
    const returnDraggable = () => {
      document.querySelector(".react-draggable").style.transform =
        "translate(0, 0)";
    };

    window.addEventListener("resize", returnDraggable);

    return () => {
      window.removeEventListener("resize", returnDraggable);
    };
  }, []);

  function signOut() {
    setLoading(true);
    Firebase.auth().signOut();
  }

  function setBackgroundImage(url) {
    setBackground(url);
    localStorage.setItem(LOCALSTORAGE_BG_KEY, url);
  }

  return (
    <Draggable bounds="parent" handle=".handle">
      <Card className="settings-container">
        <header className="handle">
          <h3>{adminPanelHeaderTitle}</h3>
          <div className="logout-button">
            <Tooltip placement="bottomRight" title="Выйти из системы">
              <Button
                icon={<LogoutOutlined />}
                shape="circle"
                onClick={signOut}
              />
            </Tooltip>
          </div>
        </header>
        <Tabs tabPosition="top">
          <Tabs.TabPane tab="Настройки" key="Настройки" forceRender={false}>
            <SettingsGeneral {...{ Firebase }} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Услуги" key="Услуги" forceRender={false}>
            <SettingsServices {...{ Firebase }} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Преимущества"
            key="Преимущества"
            forceRender={false}
          >
            <SettingsAdvantages {...{ Firebase }} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="О нас" key="О нас" forceRender={false}>
            <SettingsAboutUs {...{ Firebase }} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Контакты" key="Контакты" forceRender={false}>
            <SettingsContacts {...{ Firebase }} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Партнеры" key="Партнеры" forceRender={false}>
            <SettingsPartners {...{ Firebase }} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Фон" key="Фон" forceRender={false}>
            <SettingsBackground {...{ setBackgroundImage }} />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Draggable>
  );
}

export default Settings;
