import React from "react";
import { Row, Divider, Spin } from "antd";
import "./SettingsAboutUs.scss";
import { firebaseAboutUsUrl } from "../../../constants";
import { LoadingOutlined } from "@ant-design/icons";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ru";

function SettingAboutUs({ Firebase }) {
  const [aboutUsData, setAboutUsData] = React.useState(null);

  React.useEffect(() => {
    fetch(firebaseAboutUsUrl)
      .then((res) => res.json())
      .then((aboutUsData) => setAboutUsData(aboutUsData));
  }, []);

  function handleChange(data) {
    Firebase.database().ref("aboutUs").set(data);
  }

  return (
    <React.Fragment>
      <Divider orientation="left">О нас</Divider>
      {aboutUsData ? (
        <Row justify="space-between" align="middle" className="about-us-tab">
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                "blockQuote",
              ],
              language: "ru",
            }}
            data={aboutUsData}
            onChange={(...[, editor]) => handleChange(editor.getData())}
          />
        </Row>
      ) : (
        <Row justify="center" align="middle" className="full-height">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </Row>
      )}
    </React.Fragment>
  );
}

export default SettingAboutUs;
