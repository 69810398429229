import React from "react";
import "./Map.scss";
import { Tooltip } from "antd";
import { cities } from "./cities";
import MapImg from "./Map.png";
import { useWindowSize } from "../../hooks/useWindowSize";
import FsLightbox from "fslightbox-react";

function Map() {
  const [toggler, setToggler] = React.useState(false);
  const window = useWindowSize();

  function generateAndAnimatePoint(coords, id, tooltipTitle, index) {
    const animationTiming = 1500;
    const animatedClass = "animated";

    setTimeout(() => {
      const city = document.getElementById(id);

      city.classList.add(animatedClass);
      setTimeout(() => city.classList.remove(animatedClass), animationTiming);

      setInterval(() => {
        city.classList.add(animatedClass);
        setTimeout(() => city.classList.remove(animatedClass), animationTiming);
      }, animationTiming * cities.length);
    }, index * animationTiming);

    return (
      <Tooltip title={tooltipTitle} key={id}>
        <span id={id} className={"infinite pulse medium"} style={coords}></span>
      </Tooltip>
    );
  }

  function openFullscreenMap() {
    // if (window.width <= 768) {
    //   setToggler((prev) => !prev);
    //   setTimeout(() => {
    //     const lightboxInner = document.querySelector(
    //       ".fslightbox-source-inner"
    //     );
    //     lightboxInner.insertAdjacentHTML(
    //       "afterbegin",
    //       '<code class="map-note"><b>Карта является демонстрационной</b>\nДля более подробной информации\nо доступных местоназначениях\nобращайтесь к операторам</code>'
    //     );
    //   });
    // }
  }

  function renderNote() {
    return (
      <code className={"map-note"}>
        <b>Карта является демонстрационной</b>
        {`\n`}Для более подробной информации
        {`\n`}о доступных местоназначениях
        {`\n`}обращайтесь к операторам
      </code>
    );
  }

  return (
    <React.Fragment>
      <div className={"map"} onClick={openFullscreenMap}>
        <img src={MapImg} alt={'Карта перевозок "ВМ-Логистик"'} />

        {cities.map((city, index) =>
          generateAndAnimatePoint(city.coords, city.id, city.title, index)
        )}

        {renderNote()}
      </div>

      {/* <FsLightbox toggler={toggler} sources={[MapImg]} /> */}
    </React.Fragment>
  );
}

export default Map;
