import React from "react";
import "./Contacts.scss";

function Contacts({ data }) {
  return (
    <div className={"contacts"} id={"contacts"}>
      <h1>Контакты</h1>

      {data.contacts && (
        <div className={"contacts-text"}>
          <div className={"contacts-titles"}>
            <p>{data.contacts.title}</p>
            <p>{data.contacts.titleEng}</p>
          </div>

          <div className={"contacts-addresses"}>
            <a
              target={"_blank"}
              rel="noopener noreferrer"
              href={`https://www.google.ru/maps/place/%D0%92%D0%9C-%D0%9B%D0%BE%D0%B3%D0%B8%D1%81%D1%82%D0%B8%D0%BA/@45.0719858,38.9859773,17z/data=!3m1!4b1!4m5!3m4!1s0x40f045be03064fe5:0x1981448113d861e7!8m2!3d45.071982!4d38.988166`}
            >
              {data.contacts.address}
            </a>
            <a
              target={"_blank"}
              rel="noopener noreferrer"
              href={`https://www.google.ru/maps/place/%D0%92%D0%9C-%D0%9B%D0%BE%D0%B3%D0%B8%D1%81%D1%82%D0%B8%D0%BA/@45.0719858,38.9859773,17z/data=!3m1!4b1!4m5!3m4!1s0x40f045be03064fe5:0x1981448113d861e7!8m2!3d45.071982!4d38.988166`}
            >
              {data.contacts.addressEng}
            </a>
          </div>

          <div style={{ display: "flex" }}>
            Номер телефона|Phone number&nbsp;
            <a href={`tel:${data.contacts.phone}`}>{data.contacts.phone}</a>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contacts;
