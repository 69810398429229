import React from "react";
import { Row, Divider, Button, Tooltip } from "antd";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./SettingsPartners.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { firebasePartnersUrl } from "../../../constants";
import SettingsEditForm from "../SettingsEditForm";

function SettingsPartners({ Firebase }) {
  const [partners, setPartners] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [addMode, setAddMode] = React.useState(false);
  const [model, setModel] = React.useState(null);

  React.useEffect(() => {
    fetch(firebasePartnersUrl)
      .then((res) => res.json())
      .then((partners) => {
        setPartners(partners || []);
      });
  }, []);

  function save(item) {
    const updatedPartners = [...partners, item];

    setPartners(updatedPartners);
    Firebase.database().ref("partners").set(updatedPartners);
    setAddMode(false);
  }

  function update(item) {
    const index = partners.findIndex((x) => x.id === item.id);

    if (index !== -1) {
      let updatedPartners = partners;
      updatedPartners[index] = item;

      setPartners(updatedPartners);
      Firebase.database().ref("partners").set(updatedPartners);
    }
    setEditMode(false);
  }

  function remove(item) {
    const index = partners.findIndex((x) => x.id === item.id);
    let updatedPartners = partners;
    updatedPartners.splice(index, 1);

    setPartners(updatedPartners);
    Firebase.database().ref("partners").set(updatedPartners);
    setEditMode(false);
  }

  function getBack() {
    setAddMode(false);
    setEditMode(false);
    setModel(null);
  }

  function openItem(item) {
    setModel(item);
    setEditMode(true);
  }

  return (
    <React.Fragment>
      <Divider orientation="left">Наши партнеры</Divider>
      {partners ? (
        editMode || addMode ? (
          <SettingsEditForm
            {...{ save, update, remove, Firebase, getBack, model }}
          />
        ) : (
          <Row justify="space-between" align="middle" className="partners-tab">
            <PerfectScrollbar className="full-width mb-12">
              {(partners || []).map((item, index) => (
                <Tooltip
                  key={item.id}
                  title={item.description ? item.description : ""}
                >
                  <img
                    onClick={() => openItem(item)}
                    src={item.icon}
                    className="mr-8 partner"
                    alt=""
                  />
                </Tooltip>
              ))}
            </PerfectScrollbar>
            <Button
              type="primary"
              className="admin-button"
              onClick={() => setAddMode(true)}
            >
              Добавить
            </Button>
          </Row>
        )
      ) : (
        <Row justify="center" align="middle" className="full-height">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </Row>
      )}
    </React.Fragment>
  );
}

export default SettingsPartners;
