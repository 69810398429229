import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./RequestDialog.scss";
import InputMask from "react-input-mask";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function RequestDialog({ requestDialogOpened, closeRequestDialog, data }) {
  const [phone, setPhone] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [phoneError, setPhoneError] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    text: "",
    type: "",
  });

  function handlePressKey({ key, target }) {
    setPhoneError(false);

    if (key === "Enter") {
      setTimeout(() => {
        const allNumbersEntered = target.value.indexOf("_") === -1;

        if (allNumbersEntered) {
          sendRequest();
        } else {
          setPhoneError(true);
        }
      });
    }
  }

  function validateOrSend() {
    const allNumbersEntered = phone && phone.indexOf("_") === -1;

    if (allNumbersEntered) {
      sendRequest();
    } else {
      setPhoneError(true);
    }
  }

  function sendRequest() {
    if (!loading) {
      setLoading(true);

      fetch(
        "https://certifikey-api.herokuapp.com/api/mailer/send-logistic-request",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emailToSend: data.general.emailToSendRequests,
            description,
            phone,
          }),
        }
      )
        .then(() => {
          setSnackbar({
            open: true,
            type: "success",
            text: "Заявка отправлена. В ближайшее время мы с вами свяжемся.",
          });

          setLoading(false);
          setDescription("");
          setPhone("");
          closeRequestDialog();
        })
        .catch(() => {
          setLoading(false);
          setSnackbar({
            open: true,
            type: "error",
            text: "Неудалось запросить звонок. Попробуйте позднее.",
          });
          closeRequestDialog();
        });
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={requestDialogOpened}
        onClose={closeRequestDialog}
        aria-labelledby="Заказать звонок"
      >
        <DialogTitle>
          <p className={"request-dialog-title"}>Заказать звонок</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span className={"request-dialog-description mb-20"}>
              Мы свяжемся с Вами в ближайшее время и ответим на ваши вопросы
            </span>
          </DialogContentText>
          <InputMask
            mask="+7 (999) 99 99 999"
            value={phone}
            onChange={({ target: { value } }) => setPhone(value)}
            disabled={loading}
          >
            {() => (
              <TextField
                autoFocus
                fullWidth
                required
                error={phoneError}
                label={"телефон"}
                value={phone}
                variant={"outlined"}
                onKeyPress={handlePressKey}
                disabled={loading}
                helperText={phoneError ? "Введите телефон" : ""}
              />
            )}
          </InputMask>
          <TextField
            multiline
            fullWidth
            rows={5}
            className={"description-field"}
            label={"описание груза"}
            value={description}
            variant={"outlined"}
            onChange={({ target: { value } }) => setDescription(value)}
            disabled={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={validateOrSend}
            color={"primary"}
            className={"request-dialog-action-button main"}
            variant={"outlined"}
            disabled={loading}
          >
            {loading ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              "отправить"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.type || "error"}
          elevation={0}
          variant={"filled"}
        >
          {snackbar.text}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
}

export default RequestDialog;
