import React from "react";
import "./Partners.scss";
import { Tooltip } from "antd";
import Zoom from "react-reveal/Zoom";

function Partners({ data }) {
  return (
    <div className={"partners"} id={"partners"}>
      <h1>Наши партнеры</h1>

      <div className={"partners-list"}>
        {(data.partners || []).map((partner, index) => {
          if (index < 5) {
            return (
              <Zoom key={partner.id}>
                <div className={"partner-item"}>
                  <Tooltip placement="top" title={partner.description}>
                    <img src={partner.icon} alt={partner.description} />
                  </Tooltip>
                </div>
              </Zoom>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Partners;
