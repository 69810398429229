import { firebaseConfig } from "./enviroment";

export const LOCALSTORAGE_BG_KEY = "vm_logistic_admin_bg_url";
export const LOCALSTORAGE_THEME_KEY = "vm_logistic_admin_theme";
export const firebaseContactsUrl = `${firebaseConfig.databaseURL}/contacts.json`;
export const firebasePartnersUrl = `${firebaseConfig.databaseURL}/partners.json`;
export const firebaseServicesUrl = `${firebaseConfig.databaseURL}/services.json`;
export const firebaseAdvantagesUrl = `${firebaseConfig.databaseURL}/advantages.json`;
export const firebaseAboutUsUrl = `${firebaseConfig.databaseURL}/aboutUs.json`;
export const firebaseGeneralUrl = `${firebaseConfig.databaseURL}/general.json`;

export const getFirebaseImageUrl = (fileName) => {
  return `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/${fileName}?alt=media`;
};

export const getFirebaseFileName = (url) => {
  const parts = url.split("?")[0].split("/");

  return parts[parts.length - 1];
};
