import React from "react";
import "./Services.scss";
import { Popover } from "antd";
import Fade from "react-reveal/Fade";

function Services({ data }) {
  return (
    <div className={"services"} id={"services"}>
      <h1>Какие грузы мы возим</h1>

      <Fade left delay={250} duration={1200}>
        <div className={"services-items"}>
          {(data.services || []).map((service) => (
            <Popover
              key={service.id}
              content={service.additionalInfo}
              placement={"bottom"}
            >
              <div className={"service-item"}>
                <img src={service.icon} alt={service.description} />
                <p className={"service-description"}>{service.description}</p>
              </div>
            </Popover>
          ))}
        </div>
      </Fade>
    </div>
  );
}

export default Services;
